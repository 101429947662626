import React, { useState, useEffect } from 'react';

import { Collapse } from 'antd';

import { TransformedTrackOrder } from '@apis/trackOrders/TrackOrdersApi.types';
import { Label } from '@atoms/label/Label';
import { Loader } from '@atoms/loader/Loader';
import { Pagination } from '@atoms/pagination/Pagination';
import { OrderTicketsById } from '@customTypes/mirAndTracking/MirAndTracking.types';
import { getStatusVariant, PROGRESS_STATUSES } from '@helpers/orderStatus';
import { useUserSettings } from '@hooks/useUserSettings';
import { TableHeaderMobile } from '@molecules/tableHeaderMobile/TableHeaderMobile';
import {
    renderDateColumn,
    renderOriginDestination
} from '@organisms/trackOrders/orderList/columns';
import { PartsCount } from '@organisms/trackOrders/orderList/shared/partsCount/PartsCount';
import { ReferenceNumbers } from '@organisms/trackOrders/orderList/shared/referenceNumbers/ReferenceNumbers';
import { Tickets } from '@organisms/trackOrders/orderList/shared/ticket/Tickets';
import {
    SvgArrowFullRight,
    SvgExpandDown,
    SvgExpandUp,
    SvgTicketSmall
} from 'components/icons';

import styles from './OrderCards.module.less';

const { Panel } = Collapse;

interface PerPage {
    perPageOptions?: number[];
    perPageValue: number;
    onChangePerPage: (value: string) => void;
}

interface Props {
    dataSource?: TransformedTrackOrder[];
    isMobile: boolean;
    isLoading: boolean;
    total?: number;
    onDetailsClick: (
        e: React.MouseEvent,
        record: TransformedTrackOrder
    ) => void;
    currentPage: number;
    pageCount?: number;
    perPage?: PerPage;
    onPageChange: (page: number) => void;
    onExpand: (
        orderId: TransformedTrackOrder['orderId'],
        expanded: boolean
    ) => void;
    orderTicketsById?: OrderTicketsById;
    search?: string;
}

export const OrderCards = ({
    dataSource,
    isMobile,
    isLoading,
    onDetailsClick,
    total = 0,
    currentPage,
    pageCount,
    perPage,
    onPageChange,
    onExpand,
    orderTicketsById,
    search
}: Props) => {
    const [openedTickets, setOpenedTickets] = useState<Set<number>>(new Set());

    const { dateFormatKey, timeFormatKey } = useUserSettings();

    const getVariant = (status: PROGRESS_STATUSES) => {
        return getStatusVariant(status);
    };

    const onManualDetailsClick = (
        event: React.MouseEvent,
        item: TransformedTrackOrder
    ) => {
        event.stopPropagation();
        onDetailsClick(event, item);
    };

    const onHandleExpand = (
        orderId: TransformedTrackOrder['orderId'],
        expanded: boolean
    ) => {
        setOpenedTickets((prev) => {
            if (prev.has(orderId)) {
                prev.delete(orderId);
                return prev;
            }
            prev.add(orderId);
            return prev;
        });
        onExpand(orderId, expanded);
    };

    useEffect(() => {
        return () => {
            setOpenedTickets(new Set());
        };
    }, [isLoading]);

    const collapseHeader = (item: TransformedTrackOrder) => (
        <div
            className={styles.Section__Tickets}
            onClick={(e) => {
                if (e.target === e.currentTarget) {
                    e.stopPropagation();
                }
            }}
        >
            <div
                className={styles.Tickets__Wrapper}
                onClick={() => onHandleExpand(item.orderId, true)}
            >
                <div className={styles.Tickets__Icon}>
                    <SvgTicketSmall />
                </div>
                <div className={styles.Tickets__Text}>Show Tickets</div>
            </div>
            <div
                className={styles.Tickets__Details}
                onClick={(e) => onManualDetailsClick(e, item)}
            >
                <div className={styles.Tickets__Text}>Details</div>
                <div className={styles.Tickets__Icon}>
                    <SvgArrowFullRight id="arrow-right" color="#273C83" />
                </div>
            </div>
        </div>
    );

    return isLoading ? (
        <div className={styles.Loader}>
            <Loader size="medium" />
        </div>
    ) : (
        <>
            <TableHeaderMobile title="Orders" total={total} />
            <div className={styles.Wrapper}>
                {dataSource?.map((item: TransformedTrackOrder) => (
                    <div
                        className={styles.Container}
                        id="OrderCards"
                        key={item.id}
                    >
                        <div className={styles.Section__Main}>
                            <div className={styles.Main__Container}>
                                <div className={styles.Main__TopSection}>
                                    <div>
                                        {getVariant(item.status) ? (
                                            <Label
                                                variant={getVariant(
                                                    item.status
                                                )}
                                                name={item.status}
                                            />
                                        ) : null}
                                    </div>
                                    <div className={styles.Main__OrderType}>
                                        {item.orderType}
                                    </div>
                                </div>
                                <div className={styles.Main__BottomSection}>
                                    <div className={styles.Main__References}>
                                        <ReferenceNumbers
                                            referenceNumbers={
                                                item.referenceNumbers
                                            }
                                            search={search}
                                        />
                                    </div>
                                    <div className={styles.Main__Parts}>
                                        <PartsCount
                                            partsCount={item.partsCount}
                                            isMobile={isMobile}
                                        />
                                        <span>Products</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.Section__Delivery}>
                            <div className={styles.Delivery__Wrapper}>
                                <div className={styles.Delivery__Title}>
                                    Origin:
                                </div>
                                <div className={styles.Delivery__Content}>
                                    {renderOriginDestination({
                                        originDestination: item.origin
                                    })}
                                </div>
                            </div>
                            <div className={styles.Delivery__Wrapper}>
                                <div className={styles.Delivery__Title}>
                                    Pick Up Date:
                                </div>
                                <div className={styles.Delivery__Content}>
                                    {renderDateColumn({
                                        dateActual: item.pickUpDateActual,
                                        dateEstimated: item.pickUpDateEstimated,
                                        timeZone: item.pickUpDateTimeZone,
                                        dateFormatKey,
                                        timeFormatKey
                                    })}
                                </div>
                            </div>
                            <div className={styles.Delivery__Wrapper}>
                                <div className={styles.Delivery__Title}>
                                    Destination:
                                </div>
                                <div className={styles.Delivery__Content}>
                                    {renderOriginDestination({
                                        originDestination: item.destination
                                    })}
                                </div>
                            </div>
                            <div className={styles.Delivery__Wrapper}>
                                <div className={styles.Delivery__Title}>
                                    Delivery Date:
                                </div>
                                <div className={styles.Delivery__Content}>
                                    {renderDateColumn({
                                        dateActual: item.deliveryDateActual,
                                        dateEstimated:
                                            item.deliveryDateEstimated,
                                        timeZone: item.deliveryDateTimeZone,
                                        dateFormatKey,
                                        timeFormatKey
                                    })}
                                </div>
                            </div>
                        </div>
                        <Collapse
                            ghost
                            defaultActiveKey={
                                dataSource.length === 1
                                    ? item.id
                                    : Array.from(openedTickets)
                            }
                            expandIconPosition={'end'}
                            expandIcon={({ isActive }) =>
                                isActive ? (
                                    <SvgExpandUp
                                        id="expand-down"
                                        color="#273C83"
                                        className={styles.ExpandIcon}
                                        onClick={() =>
                                            onHandleExpand(item.orderId, true)
                                        }
                                    />
                                ) : (
                                    <SvgExpandDown
                                        data-testid="expand-up"
                                        color="#273C83"
                                        className={styles.ExpandIcon}
                                        onClick={() =>
                                            onHandleExpand(item.orderId, true)
                                        }
                                    />
                                )
                            }
                        >
                            <Panel header={collapseHeader(item)} key={item.id}>
                                <Tickets
                                    searchResult={search}
                                    isLoading={
                                        orderTicketsById?.[item?.orderId]
                                            ?.isLoading
                                    }
                                    tickets={
                                        orderTicketsById?.[item?.orderId]?.data
                                    }
                                />
                            </Panel>
                        </Collapse>
                    </div>
                ))}
                {pageCount && pageCount > 1 ? (
                    <div className={styles.Pagination}>
                        <Pagination
                            current={currentPage}
                            total={total}
                            onChange={onPageChange}
                            pageSize={perPage?.perPageValue}
                        />
                    </div>
                ) : null}
            </div>
        </>
    );
};
