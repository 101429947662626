import React from 'react';

import styles from './TableHeaderMobile.module.less';

export const TableHeaderMobile = ({
    title,
    total = 0
}: {
    title: string;
    total?: number;
}) => {
    return (
        <div className={styles.Header}>
            <div className={styles.Header__Title}>{title}</div>
            <div className={styles.Header__Quantity}>
                <div className={styles.Header__Results}>Results: {total}</div>
            </div>
        </div>
    );
};
