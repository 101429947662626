import { useEffect, useRef, useState } from 'react';

import {
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState
} from '@microsoft/signalr';

import AuthApi from '@apis/auth/auth.api';
import { AppController } from '@configs/AppController';
import { B2CController } from '@configs/B2CController';

interface Args<T> {
    hub: string;
    eventCallBack: (data: T) => void;
    eventName: string;
}

export const useSignalR = <T>({ hub, eventCallBack, eventName }: Args<T>) => {
    const socketsPath = AppController.getInstance().config?.api_sockets;
    const b2cConfig = B2CController.getInstance().getConfig();

    const connection = useRef<HubConnection | null>(null);
    const [token, setToken] = useState<string>(AuthApi.getAccessToken());
    const [isTokenRefreshing, setIsTokenRefreshing] = useState(false);
    const isManuallyClosed = useRef(false);

    const initializeConnection = (newToken?: string): HubConnection => {
        const currentToken = newToken || token;
        const newConnection = new HubConnectionBuilder()
            .withUrl(`${socketsPath}/hubs/${hub}`, {
                accessTokenFactory: () => currentToken,
                withCredentials: true
            })
            .build();

        newConnection.keepAliveIntervalInMilliseconds = 15000;
        newConnection.serverTimeoutInMilliseconds = 60000;
        connection.current = newConnection;

        return newConnection;
    };

    const closeConnection = async () => {
        if (
            connection &&
            connection.current &&
            connection.current.state !== HubConnectionState.Disconnected
        ) {
            isManuallyClosed.current = true;
            await connection.current.stop();
            console.log('SignalR connection closed manually');
        }
    };

    const openConnection = async (newToken?: string) => {
        if (
            connection &&
            connection.current &&
            connection.current.state === HubConnectionState.Connected
        ) {
            console.log('Connection is already active');
            return;
        }

        isManuallyClosed.current = false;
        const newConnection = initializeConnection(newToken);

        newConnection.on(eventName, (message: T) => {
            eventCallBack(message);
        });

        newConnection.onclose(async (error) => {
            console.error('SignalR connection closed', error);
            if (!isManuallyClosed.current) {
                console.log('Reconnecting...');
                await refreshToken();
            } else {
                console.log(
                    'Connection was closed manually. Skipping reconnect.'
                );
            }
        });

        try {
            await newConnection.start();
            console.log('SignalR connection started');
            connection.current = newConnection;
        } catch (error) {
            console.error('Error starting SignalR connection:', error);
        }
    };

    const refreshToken = async (): Promise<void> => {
        if (isTokenRefreshing) return;

        setIsTokenRefreshing(true);

        try {
            const refreshTokenValue = AuthApi.getRefreshToken();

            const newToken = await AuthApi.refreshToken({
                refresh_token: refreshTokenValue,
                response_type: 'token id_token',
                client_id: b2cConfig.clientId,
                grant_type: 'refresh_token'
            });

            if (newToken) {
                console.log('Token successfully refreshed');
                setToken(newToken.data.access_token);

                if (connection && connection.current) {
                    await connection.current.stop();
                }

                await openConnection(newToken.data.access_token);
            } else {
                console.error('No token received from Auth API');
            }
        } catch (error) {
            console.error('Error refreshing token:', error);
        } finally {
            setIsTokenRefreshing(false);
        }
    };

    useEffect(() => {
        let retryCount = 0;
        const maxRetries = 5;

        const interval = setInterval(async () => {
            if (
                !isManuallyClosed.current &&
                connection?.current?.state ===
                    HubConnectionState.Disconnected &&
                retryCount < maxRetries &&
                !isTokenRefreshing
            ) {
                console.log(
                    'SignalR connection inactive, attempting to reconnect...'
                );
                retryCount++;
                await refreshToken();
            } else if (retryCount >= maxRetries) {
                console.log(
                    'Max reconnection attempts reached. Stopping retries.'
                );
                clearInterval(interval);
            }
        }, 30000);

        return () => {
            clearInterval(interval);
        };
    }, [connection]);

    return {
        openConnection,
        closeConnection
    };
};
