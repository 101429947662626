import { createApi } from '@reduxjs/toolkit/query/react';

import { Client } from '@apis/clients/ClientsApi.types';
import { baseQueryWithIntercept } from '@apis/fetchBaseQuery';
import { GetPermissionsResponse } from '@apis/permissions/Permissions.type';
import {
    AccountsSubAccounts,
    CreateBasicUserDTO,
    AdminPanelResponse,
    REQUEST_PATH,
    CreateUserDTO,
    GetChoiceAdminsResponse,
    GetChoiceAdminDTO,
    GetChoiceAdminsTransformedResponse,
    GetAdminsResponse,
    GetUsersResponse,
    EditBasicUserDTO,
    EditUserDTO,
    GetAvailableWarehousesResponse,
    GetAvailableTransportServicesResponse,
    GetUserInfoResponse,
    GetUserSettingsResponse,
    UserSettings,
    GetUsersDTO,
    GetQuickAccessResponse,
    GetTransformedQuickAccessResponse,
    TransformedQuickAccessResponse,
    UpdateQuickAccessDTO
} from '@apis/users/UsersApi.types';
import {
    RequestStatusWithData,
    RequestStatusWithEmptyData
} from '@customTypes/api/api.types';
import { getBasePaginationBody } from '@helpers/getBasePaginationBody';

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: baseQueryWithIntercept,
    tagTypes: ['USER_SETTINGS', 'QUICK_LINKS'],
    endpoints: (builder) => ({
        getAdminUsers: builder.mutation<GetAdminsResponse, GetUsersDTO>({
            query: (dto) => ({
                url: REQUEST_PATH.GET_ADMIN,
                method: 'POST',
                headers: {
                    'cache-id': dto.cacheId
                },
                body: getBasePaginationBody({
                    ...dto,
                    filterFields: ['NameOrEmail'],
                    withoutBaseQueries: true
                })
            })
        }),
        getAdminUsersClients: builder.query<
            RequestStatusWithData<Client[]>,
            boolean
        >({
            query: (showOnlyActive) => ({
                url: REQUEST_PATH.GET_ADMINS_CLIENT.replace(
                    ':status',
                    String(showOnlyActive)
                )
            })
        }),
        getChoiceAdmins: builder.mutation<
            GetChoiceAdminsTransformedResponse,
            GetChoiceAdminDTO
        >({
            query: (dto) => ({
                url: REQUEST_PATH.CHOICE_ADMINS,
                method: 'POST',
                headers: {
                    'cache-id': dto.cacheId
                },
                body: getBasePaginationBody({
                    ...dto,
                    withoutBaseQueries: true,
                    fixedSorter: 'surName',
                    filterFields: ['NameOrEmail']
                })
            }),
            transformResponse: (
                apiResponse: GetChoiceAdminsResponse,
                meta: { response: Response }
            ): GetChoiceAdminsTransformedResponse => {
                return {
                    ...apiResponse,
                    cacheId:
                        meta?.response?.headers.get('cache-id') || undefined
                };
            }
        }),
        getUsers: builder.mutation<GetUsersResponse, GetUsersDTO>({
            query: (dto) => ({
                url: REQUEST_PATH.GET_USERS,
                method: 'POST',
                headers: {
                    'cache-id': dto.cacheId
                },
                body: getBasePaginationBody({
                    ...dto,
                    filterFields: ['NameOrEmailOrProfile'],
                    withoutBaseQueries: true
                })
            })
        }),
        getUserInfo: builder.query<GetUserInfoResponse, string>({
            query: (id) => ({
                url: REQUEST_PATH.GET_USER_INFO.replace(':id', id)
            })
        }),
        getUsersClients: builder.query<
            RequestStatusWithData<Client[]>,
            boolean
        >({
            query: (active: boolean) => ({
                url: REQUEST_PATH.GET_USERS_CLIENTS.replace(
                    ':showOnlyActive',
                    String(active)
                )
            })
        }),
        getUserSettings: builder.query<GetUserSettingsResponse, void>({
            query: () => ({
                url: REQUEST_PATH.GET_USER_SETTINGS
            }),
            providesTags: [{ type: 'USER_SETTINGS', id: 'USER_SETTINGS' }]
        }),
        updateUserSettings: builder.mutation<
            RequestStatusWithEmptyData,
            UserSettings
        >({
            query: (dto) => ({
                url: REQUEST_PATH.UPDATE_USER_SETTINGS,
                method: 'POST',
                body: dto
            }),
            invalidatesTags: ['USER_SETTINGS']
        }),
        getAccountsAndSubAccounts: builder.query<
            AccountsSubAccounts,
            {
                onlyTransactional: boolean;
            }
        >({
            query: ({ onlyTransactional }) => ({
                url: REQUEST_PATH.ACCOUNTS_AND_SUB_ACCOUNTS.replace(
                    ':onlyTransactional',
                    String(onlyTransactional)
                )
            })
        }),
        createChoiceAdmin: builder.mutation<
            AdminPanelResponse,
            CreateBasicUserDTO
        >({
            query: (dto) => ({
                url: REQUEST_PATH.CREATE_CHOICE_ADMIN,
                method: 'POST',
                body: dto
            })
        }),
        editChoiceAdmin: builder.mutation<AdminPanelResponse, EditBasicUserDTO>(
            {
                query: (dto) => ({
                    url: REQUEST_PATH.EDIT_CHOICE_ADMIN,
                    method: 'PUT',
                    body: dto
                })
            }
        ),
        createAdmin: builder.mutation<AdminPanelResponse, CreateBasicUserDTO>({
            query: (dto) => ({
                url: REQUEST_PATH.CREATE_ADMIN,
                method: 'POST',
                body: dto
            })
        }),
        editAdmin: builder.mutation<AdminPanelResponse, EditBasicUserDTO>({
            query: (dto) => ({
                url: REQUEST_PATH.EDIT_ADMIN,
                method: 'PUT',
                body: dto
            })
        }),
        createUser: builder.mutation<AdminPanelResponse, CreateUserDTO>({
            query: (dto) => ({
                url: REQUEST_PATH.CREATE_USER,
                method: 'POST',
                body: dto
            })
        }),
        editUser: builder.mutation<AdminPanelResponse, EditUserDTO>({
            query: (dto) => ({
                url: REQUEST_PATH.EDIT_USER,
                method: 'PUT',
                body: dto
            })
        }),
        deleteUser: builder.mutation<RequestStatusWithEmptyData, string>({
            query: (id) => ({
                url: REQUEST_PATH.DELETE_USER.replace(':id', id),
                method: 'DELETE'
            })
        }),
        updateLastLogin: builder.mutation<AdminPanelResponse, void>({
            query: (dto) => ({
                url: REQUEST_PATH.UPDATE_LAST_LOGIN,
                method: 'PATCH',
                body: dto
            })
        }),
        deactivateUser: builder.mutation<RequestStatusWithEmptyData, string>({
            query: (userId) => ({
                url: REQUEST_PATH.DEACTIVATE_USER.replace(':id', userId),
                method: 'PATCH'
            })
        }),
        activateUser: builder.mutation<RequestStatusWithEmptyData, string>({
            query: (userId) => ({
                url: REQUEST_PATH.ACTIVATE_USER.replace(':id', userId),
                method: 'PATCH'
            })
        }),
        getPermissions: builder.query<
            RequestStatusWithData<GetPermissionsResponse>,
            void
        >({
            query: () => ({ url: REQUEST_PATH.GET_PERMISSIONS })
        }),
        getAvailableWarehouses: builder.query<
            GetAvailableWarehousesResponse,
            string
        >({
            query: (profileId) => ({
                url: REQUEST_PATH.GET_AVAILABLE_WAREHOUSES.replace(
                    ':profileId',
                    profileId
                )
            })
        }),
        getAvailableTransportServices: builder.query<
            GetAvailableTransportServicesResponse,
            void
        >({
            query: () => ({
                url: REQUEST_PATH.GET_AVAILABLE_TRANSPORT_SERVICES,
                headers: {
                    'Api-Version': '3.1'
                }
            })
        }),

        getHomeQuickLinks: builder.query<
            GetTransformedQuickAccessResponse,
            void
        >({
            providesTags: ['QUICK_LINKS'],
            query: () => ({
                url: REQUEST_PATH.GET_HOME_QUICK_ACCESS
            }),
            transformResponse(
                response: GetQuickAccessResponse
            ): GetTransformedQuickAccessResponse {
                const groups = response.data?.groups;
                const permissions = response.data?.permissions;

                const formattedDataByGroups: TransformedQuickAccessResponse[] =
                    groups
                        ?.map((item) => {
                            return {
                                title: item.displayName,
                                groupKeys: item.keys,
                                sort: item.sortOrder,
                                permissions: permissions
                                    .filter(
                                        (el) =>
                                            el.permissionGroupId ===
                                            item.permissionGroupId
                                    )
                                    .sort(
                                        (el1, el2) =>
                                            el1.sortOrder - el2.sortOrder
                                    )
                            };
                        })
                        .sort((el1, el2) => el1.sort - el2.sort);

                const permissionWithoutGroup = permissions.filter((item) => {
                    return !item.permissionGroupId;
                });

                const formattedDataByPermission: TransformedQuickAccessResponse[] =
                    permissionWithoutGroup.map((item) => {
                        return {
                            title: item.displayName,
                            groupKeys: item.keys,
                            sort: item.sortOrder,
                            permissions: [
                                {
                                    permissionGroupId: null,
                                    displayName: item.displayName,
                                    permissionId: item.permissionId,
                                    keys: item.keys,
                                    sortOrder: item.sortOrder,
                                    isSelected: item.isSelected
                                }
                            ]
                        };
                    });

                return {
                    statusMessage: response.statusMessage,
                    statusCode: response.statusCode,
                    data: [
                        ...formattedDataByPermission,
                        ...formattedDataByGroups
                    ]
                };
            }
        }),

        updateQuickAccess: builder.mutation<void, UpdateQuickAccessDTO>({
            query: (dto) => ({
                url: REQUEST_PATH.UPDATE_HOME_QUICK_ACCESS,
                method: 'POST',
                body: dto
            }),
            invalidatesTags: ['QUICK_LINKS']
        })
    })
});

export const {
    useLazyGetAccountsAndSubAccountsQuery,
    useCreateChoiceAdminMutation,
    useCreateAdminMutation,
    useCreateUserMutation,
    useGetChoiceAdminsMutation,
    useGetUsersMutation,
    useGetUserInfoQuery,
    useGetUsersClientsQuery,
    useGetAdminUsersMutation,
    useGetAdminUsersClientsQuery,
    useUpdateLastLoginMutation,
    useDeactivateUserMutation,
    useActivateUserMutation,
    useEditChoiceAdminMutation,
    useEditAdminMutation,
    useEditUserMutation,
    useDeleteUserMutation,
    useGetPermissionsQuery,
    useGetAvailableWarehousesQuery,
    useGetAvailableTransportServicesQuery,
    useGetUserSettingsQuery,
    useUpdateUserSettingsMutation,
    useGetHomeQuickLinksQuery,
    useUpdateQuickAccessMutation
} = usersApi;
