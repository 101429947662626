import React from 'react';

import { TreeSelect as AntTreeSelect } from 'antd';

import { SearchBadge } from '@atoms/searchBadge/SearchBadge';
import { getAccountTagLabel } from '@helpers/getTagLabel';
import { SvgSuitcase } from '@icons/index';
import { Filter, FilterProps } from '@molecules/filters/filter/Filter';
import { TreeSelect } from '@molecules/treeSelect/TreeSelect';
import { useTreeFilter } from '@organisms/treeFilter/useTreeFilter';

import styles from './TreeFilter.module.less';

const { SHOW_PARENT, SHOW_CHILD } = AntTreeSelect;

interface Props extends Partial<FilterProps> {
    selectedFilterItems?: string[];
    onApplyAccounts: (value: string[]) => void;
    filterDataSetting: string;
    showCheckedStrategy?: typeof SHOW_PARENT | typeof SHOW_CHILD;
    withoutIcon?: boolean;
}

export const TreeFilter = ({
    selectedFilterItems = [],
    onApplyAccounts,
    filterDataSetting,
    showCheckedStrategy,
    withoutIcon = false,
    ...props
}: Props) => {
    const isAccountAndSubsSetting = filterDataSetting === 'accountsAndSubs';

    const {
        onTreeInit,
        areSelectedKeysChanged,
        selectedKeys,
        handleChange,
        popupRef,
        treeData,
        getCustomPopupContainer,
        onRemoveSearchBadge,
        accounts,
        accountsLoading
    } = useTreeFilter({ selectedFilterItems, isAccountAndSubsSetting });

    return (
        <Filter
            {...props}
            icon={!withoutIcon && <SvgSuitcase color="#273c83" width={22} />}
            filterName="Account"
            dataTestId="AccountFilter"
            isSelected={!!selectedFilterItems?.length}
            onOpenFilter={onTreeInit}
            onApply={() => onApplyAccounts(selectedKeys)}
            isApplyDisabled={!areSelectedKeysChanged}
            mainFilterContent={
                <div ref={popupRef} className={styles.Container}>
                    <TreeSelect
                        loading={accountsLoading}
                        treeData={treeData}
                        value={selectedKeys}
                        onChange={handleChange}
                        placeholder="Account"
                        className={styles.Select}
                        showCheckedStrategy={showCheckedStrategy}
                        maxTagCount={1}
                        open={true}
                        maxTagPlaceholder={(omittedValues) => (
                            <div className={styles.Select__Omitted}>
                                {omittedValues.length}
                            </div>
                        )}
                        tagRender={({ value }) => (
                            <SearchBadge
                                text={getAccountTagLabel(value, accounts)}
                                key={value}
                                id={value}
                                removeBadges={onRemoveSearchBadge}
                            />
                        )}
                        dropdownClassName={styles.TreeSelectDropdown}
                        getPopupContainer={getCustomPopupContainer}
                        treeDefaultExpandAll={
                            !(treeData && treeData?.length > 1)
                        }
                    />
                </div>
            }
            destroyOnClose
        />
    );
};
