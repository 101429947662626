import { ReactNode } from 'react';

import { TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/es/table/interface';

import { GetMaterialsDTO } from '@apis/materialsInReview/MaterialsInReview.types';
import {
    GetOrdersDTO,
    OrderTicket
} from '@apis/trackOrders/TrackOrdersApi.types';
import { Account } from '@apis/users/UsersApi.types';
import { InitialSearchParams } from 'utils/helpers/paramsHandler';
import {
    SorterResult,
    Sorting,
    VoidFunction
} from 'utils/types/general/general.types';

export interface FilterItem {
    filterName?: string;
    customId?: string;
    icon?: ReactNode;
    withArrow?: boolean | null;
    filterComponent?: ReactNode;
    isVisible: boolean;
    isSelected?: boolean;
    onClick?: VoidFunction;
}

export enum MIR_SEARCH_PARAMS {
    MIR_REASONS = 'mirReasons',
    CARRIERS = 'carriers',
    WAREHOUSES = 'warehouses'
}

export enum TRACKING_SEARCH_PARAMS {
    PART_CONDITION = 'partConditionTypes',
    DELIVERY_TYPES = 'deliveryTypes',
    ORIGIN_LOCATIONS = 'originLocations',
    DESTINATION_LOCATIONS = 'destinationLocations',
    TRANSPORT_TYPES = 'transportTypes'
}

export interface MaterialsListSearchParams extends InitialSearchParams {
    [MIR_SEARCH_PARAMS.MIR_REASONS]?: string;
    [MIR_SEARCH_PARAMS.CARRIERS]?: string;
    [MIR_SEARCH_PARAMS.WAREHOUSES]?: string;
}

export interface DocumentModalData {
    mode: DocumentModalMode;
    documentType?: string;
    ticketDocumentHistoryID?: number;
}

export interface ConfirmModalData {
    docName: string;
    ticketDocumentHistoryId: number;
}

export enum DocumentModalMode {
    ADD = 'add',
    edit = 'edit'
}

export type OrderTicketPayload = {
    data: OrderTicket[];
    isLoading: boolean;
    expanded: boolean;
};

export type OrderTicketsById = Record<string, OrderTicketPayload>;

export interface OrdersListSearchParams extends InitialSearchParams {
    sortingData?: Sorting;
    orderTypes?: string;
    transportTypes?: string;
    partConditionTypes?: string;
    deliveryTypes?: string;
    dateFrom?: string;
    dateTo?: string;
    dateType?: string;
    originLocations?: string;
    destinationLocations?: string;
    showDangerousGoods?: string;
    showWatchList?: string;
}

export type NewParamsType =
    | MaterialsListSearchParams
    | OrdersListSearchParams
    | undefined;

export interface InitialProps {
    config: GetMaterialsDTO | GetOrdersDTO;
    getData: (dto: GetMaterialsDTO | GetOrdersDTO) => void;
    setParams: (newParams?: NewParamsType, replace?: boolean) => void;
    removeParam: (params: string[]) => void;
}

export interface TableChangeProps<T extends Record<'id', string>>
    extends Omit<InitialProps, 'removeParam'> {
    tableProps: {
        newPagination: TablePaginationConfig;
        filters: Record<string, FilterValue | null>;
        sorter: SorterResult<T>;
        secondSortField?: string | undefined;
    };
    sorting: Sorting;
    status?: string;
}

export interface ChangePerPageProps extends Omit<InitialProps, 'removeParam'> {
    page: number;
    pageSize: number;
    status?: string;
}

export interface AccountChangeWithParamsProps extends InitialProps {
    data: string[];
    allAccounts?: Account[];
    filter: string;
    status?: string;
}
