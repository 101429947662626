import { OrderWarehouseDataItem } from '@apis/trackOrders/TrackOrdersApi.types';
import { SubAccount } from '@apis/users/UsersApi.types';
import { capitalize } from '@helpers/stringHelper';

export interface TreeDataType {
    name: string;
    id?: number;
    children: {
        id: number;
        name: string;
        isTransactional?: boolean;
    }[];
}

interface TreeHelperArgs {
    currentData: string[];
    allData?: TreeDataType[];
}

interface GetChildrenType {
    title: string;
    value: string;
    children: GetChildrenType[] | null;
}

export interface FormattedTreeData {
    name: string;
    code: string;
    description?: string;
    children?: FormattedTreeData[] | null;
}

export const treeDataHelper = ({
    currentData,
    allData
}: TreeHelperArgs): TreeDataType[] => {
    const parentItems = currentData.filter((childKeys: string) => {
        const idAndGroup = childKeys.split('_');

        return !idAndGroup[1];
    });

    const elementWithoutChildren = allData?.filter((item: TreeDataType) => {
        const isParentElement = item.id && !item.children.length;
        const isParentChild = parentItems.includes(item.name);

        return isParentElement && isParentChild;
    });

    const parents = Array.from(
        new Set(
            currentData
                .filter((item) => item.includes('_'))
                .map((item) => item.split('_')[1])
        )
    );
    const withoutParents = currentData.filter(
        (item) => !parents.find((el) => el === item)
    );
    const dataForFormatting = parents.length
        ? parents
        : withoutParents.map((item) => item.split('_')[1]);

    const dataWithNestedElements: TreeDataType[] = dataForFormatting.reduce(
        (acc, item) => {
            if (!item) return [];

            const chosenChildrenId = withoutParents
                .filter((el) => el.includes(item))
                .map((el) => {
                    const chosenTreeNode = el.split('_');
                    const childId = Number(chosenTreeNode[0]);
                    const parent = chosenTreeNode[1];
                    const currentGroup = allData?.find(
                        (item) => item.name === parent
                    );
                    const chosenChild = currentGroup?.children.find((child) => {
                        return child.id === childId;
                    });

                    return {
                        id: childId,
                        name: chosenChild?.name ?? '',
                        isTransactional: chosenChild?.isTransactional
                    };
                });

            return [
                ...acc,
                {
                    name: item,
                    children: chosenChildrenId
                }
            ];
        },
        [] as TreeDataType[]
    );

    const formattedElementWithoutChildren: TreeDataType[] =
        elementWithoutChildren
            ? elementWithoutChildren.map((item) => {
                  return {
                      id: item.id,
                      name: item.name,
                      children: []
                  };
              })
            : [];

    return [...formattedElementWithoutChildren, ...dataWithNestedElements];
};

export const getParsedWarehouses = (
    warehouses?: OrderWarehouseDataItem
): FormattedTreeData[] | undefined => {
    return warehouses?.availableSslList.map((item): FormattedTreeData => {
        return {
            name: item.region,
            code: item.regionCode,
            children: item.availableCountries.map((el) => {
                return {
                    name: el.country,
                    code: el.countryCode,
                    children: el.sslList.map((data) => {
                        return {
                            name: data.country,
                            code: data.code,
                            description: data.city
                        };
                    })
                };
            })
        };
    });
};

export const getChildren = ({
    withCode,
    parentCode,
    data
}: {
    withCode: boolean;
    parentCode: string;
    data?: FormattedTreeData[] | null;
}): GetChildrenType[] | null => {
    if (!data) return null;

    return data.map((item) => ({
        title: `${withCode ? `${item.code}, ` : ''}${capitalize(
            item?.name || ''
        )}${
            item?.description ? `, ${capitalize(item?.description || '')}` : ''
        }`,
        value: `${parentCode}_${item.code}`,

        children: getChildren({
            withCode,
            parentCode: `${parentCode}_${item.code}`,
            data: item.children
        })
    }));
};

export const getNestedData = (
    withCode: boolean,
    parentCode: string,
    data?: FormattedTreeData[] | null
) => {
    if (!data) return [];

    return (
        data.map(({ name, code, children }) => {
            const nameCapitalized = capitalize(name);

            const childrenListData = getChildren({
                withCode,
                parentCode: `${parentCode}_${code}`,
                data: children
            });

            return {
                title: nameCapitalized,
                value: `${parentCode}_${code}`,
                children: childrenListData
            };
        }) || []
    );
};

export const getSubAccountDescription = (
    id: number,
    data?: SubAccount[]
): string => {
    if (data?.length) {
        return data.find((item) => item.subAccountId === id)?.description || '';
    }
    return '';
};

export const getSubAccountIdBySubAccNumber = (
    subAccountNumber: string,
    data?: SubAccount[]
) => {
    if (data?.length) {
        return (
            data.find((item) => item.subAccountNumber === subAccountNumber)
                ?.subAccountId || null
        );
    }
    return null;
};
