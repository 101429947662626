import { getSubAccountDescription } from '@helpers/treeHelper';
import {
    GetShippingServices,
    ShippingServices
} from 'APIServices/trackOrders/TrackOrdersApi.types';
import { Account } from 'APIServices/users/UsersApi.types';
import { SelectedFilterItems } from 'utils/hooks/useOrdersListFilters';

export interface SelectedAccountsAndSubsData {
    selectedAccountsAndSubs: SelectedFilterItems;
    onRemoveAccountsAndSubs: (id: string) => void;
}

export const useSelectedAccountsAndSubs = ({
    data,
    rawData,
    onApply
}: {
    data?: string[];
    rawData?: Account[];
    onApply: (data: string[]) => void;
}): SelectedAccountsAndSubsData => {
    const accountMap = rawData
        ? new Map(rawData.map((account) => [account.accountId, account]))
        : new Map();

    const selectedAccountsAndSubs = (
        data?.map((item) => {
            const [accId, subId] = item.split('-').map(Number);
            const account = accountMap.get(accId);

            if (!account) {
                return null;
            }

            const title = subId
                ? getSubAccountDescription(subId, account.subAccounts)
                : account.accountNumber.toString();

            return {
                title: title,
                value: item
            };
        }) || []
    ).filter(Boolean) as SelectedFilterItems;

    const onRemoveAccountsAndSubs = (idToRemove: string) => {
        const isSubAccount = idToRemove.includes('-');
        const withoutRemoved = (data || []).filter((item) => {
            if (isSubAccount) {
                return item !== idToRemove;
            } else {
                const [accountId] = item.split('-');
                return accountId !== idToRemove;
            }
        });
        onApply(withoutRemoved);
    };

    return {
        selectedAccountsAndSubs,
        onRemoveAccountsAndSubs
    };
};

export const useSelectedTransportType = (
    selectedTransportTypes?: string[],
    allTransportTypes?: GetShippingServices['data']
): {
    selectedTransportTypes: {
        title: string;
        value: string;
        shippingIds: number[] | null;
    }[];
} => {
    if (!selectedTransportTypes || !allTransportTypes)
        return { selectedTransportTypes: [] };

    const transportMap = new Map(
        allTransportTypes.map((item) => [item.levelId, item])
    );

    const collectShippingIds = (
        currentItem: GetShippingServices['data'][number]
    ): number[] => {
        const currentShippingIds = currentItem.shippingServiceIds || [];
        const childShippingIds =
            currentItem.children?.flatMap((child) =>
                collectShippingIds(child)
            ) || [];
        return [...currentShippingIds, ...childShippingIds];
    };

    const getShippingDataRecursively = (
        currentItem: GetShippingServices['data'][number],
        levels: number[],
        parentPath: string
    ): {
        title: string;
        value: string;
        shippingIds: number[] | null;
    } | null => {
        if (levels.length === 0) {
            const allShippingIds = collectShippingIds(currentItem);
            return {
                title: currentItem.levelDescription,
                value: parentPath,
                shippingIds: allShippingIds
            };
        }

        const [nextLevelId, ...remainingLevels] = levels;
        const nextItem = currentItem.children?.find(
            (child) => child.levelId === nextLevelId
        );

        if (!nextItem) return null;

        const currentPath = `${parentPath}_${nextLevelId}`;
        return getShippingDataRecursively(
            nextItem,
            remainingLevels,
            currentPath
        );
    };

    const selectedItems = selectedTransportTypes
        .map((item) => {
            const levels = item.split('_').map(Number);
            const parentId = levels[0];
            const parentItem = transportMap.get(parentId);

            if (!parentItem) return null;

            return getShippingDataRecursively(
                parentItem,
                levels.slice(1),
                String(parentId)
            );
        })
        .filter(Boolean) as {
        title: string;
        value: string;
        shippingIds: number[] | null;
    }[];

    return { selectedTransportTypes: selectedItems };
};

interface TransportTree {
    name: string;
    code: string;
    children: TransportTree[] | null;
}

const getTransportTree = (
    data?: ShippingServices[]
): TransportTree[] | null => {
    if (!data) return null;

    return data.map((item) => ({
        name: item.levelDescription,
        code: String(item.levelId),
        children: getTransportTree(item.children)
    }));
};

export const getParsedTransportData = (
    allTransportTypes?: GetShippingServices['data']
) => {
    if (!allTransportTypes) return [];

    return allTransportTypes.map((item) => {
        return {
            name: item.levelDescription,
            code: String(item.levelId),
            children: getTransportTree(item.children)
        };
    });
};
