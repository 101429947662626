export const defaultPageTitle = 'Compass - Choice Logistics';

export const pageHeadersList = {
    HOME_PAGE: {
        ROOT: '- Home -'
    },
    TRACK_ORDERS: {
        ROOT: '- Track Orders -',
        ORDER_DETAILS: '- Order Details -'
    },
    CUSTOMER_ORDERS: {
        GOOD_PARTS: '- Customer Delivery - Good Products -',
        GOOD_PARTS_UPLOAD: '- Customer Delivery - Good Products (Upload) -'
    },
    REPLENISHMENTS: {
        GOOD_PARTS: '- Replenishments - Good Products -',
        GOOD_PARTS_UPLOAD: '- Replenishments - Good Products (Upload) -'
    },
    WAREHOUSE_TRANSFER: {
        GOOD_PARTS: '- Warehouse Transfers - Good Products -',
        GOOD_PARTS_UPLOAD: '- Warehouse Transfers - Good Products (Upload) -',
        DEFECTIVE_PARTS: '- Warehouse Transfers - Defective Products -',
        DEFECTIVE_PARTS_UPLOAD:
            '- Warehouse Transfers - Defective Products (Upload) -'
    },
    RETURN_PARTS: {
        DEFECTIVE_TO_CHOICE: '- Returns - Defective to Choice -',
        DEFECTIVE_TO_CHOICE_UPLOAD:
            '- Returns - Defective to Choice (Upload) -',
        GOOD_TO_CHOICE: '- Returns - Good to Choice -',
        DEFECTIVE_FROM_CHOICE: '- Returns - Defective from Choice -',
        DEFECTIVE_FROM_CHOICE_UPLOAD:
            '- Returns - Defective from Choice (Upload) -',
        GOOD_FROM_CHOICE: '- Returns - Good from Choice -'
    },
    MANAGE_PARTS: {
        PART_MASTER: '- Manage Products - Product Master -',
        SUBSTITUTE_PARTS: '- Manage Products - Substitute Products -'
    },
    MANAGE_INVENTORY: {
        INVENTORY_ON_HAND: '- Manage Inventory - Inventory On Hand -',
        INVENTORY_TRANSACTION_HISTORY:
            '- Manage Inventory - Inventory Transaction History -',
        MATERIAL_IN_REVIEW: '- Manage Inventory - Material In Review -',
        MATERIAL_IN_REVIEW_DETAILS:
            '- Manage Inventory - Material In Review - Details -',
        MATERIAL_IN_REVIEW_ANALYSIS:
            '- Manage Inventory - Materials In Review Analysis -',
        MISSING_PARTS: '- Manage Inventory - Missing Products -',
        // OUTSTANDING_INBOUNDS: '- Manage Inventory - Outstanding Inbounds -',
        // OUTSTANDING_RETURNS: '- Manage Inventory - Outstanding Returns -',
        LOCATE_CLOSEST_WAREHOUSE:
            '- Manage Inventory - Locate Closest Warehouse -'
    },
    FINANCIALS: {
        INVOICES: '- Financials - Invoices -',
        STORAGE_BILLING: '- Financials - Storage Billing -',
        INVOICE_BILLING: '- Financials - Invoice Billing -',
        SPEND_ANALYSIS: '- Financials - Spend Analysis -',
        DUTIES_TAXES_ANALYSIS: '- Financials - Duties & Taxes Analysis -'
    },
    ANALYTICS: {
        ORDER_HISTORY: '- Analytics - Order History -',
        CYCLE_COUNT_PERFORMANCE: '- Analytics - Cycle Count Performance -',
        GLOBAL_CAPABILITIES: '- Analytics - Global Capabilities -',
        DOCK_TO_STOCK: '- Analytics - Dock to Stock -',
        OUTBOUND_DRIVE_FILL_RATE: '- Analytics - Outbound Drive Fill Rate -',
        OUTBOUND_DRIVE_PERFORMANCE:
            '- Analytics - Outbound Drive Performance -',
        TENDER_TO_CARRIER_PERFORMANCE:
            '- Analytics - Tender to Carrier Performance -'
    },
    ADMINISTRATION: {
        ADMIN: {
            ROOT: '- Administration - Admins -',
            CREATE: '- Administration - Admins - New Admin -',
            EDIT: '- Administration - Admins - Edit Admin -'
        },
        USER: {
            ROOT: '- Administration - Users -',
            CREATE: '- Administration - Users - New User -',
            EDIT: '- Administration - Users - Edit User -'
        },
        PROFILE: {
            ROOT: '- Administration - Profiles -',
            CREATE: '- Administration - Profiles - New Profile -',
            EDIT: '- Administration - Profiles - Edit Profile -'
        },
        CHOICE_ADMIN: {
            ROOT: '- Administration - Choice Administrators -',
            CREATE: '- Administration - Choice Administrators - New Choice Admin -',
            EDIT: '- Administration - Choice Administrators - Edit Choice Admin -'
        }
    },
    SERVICE_PAGES: {
        USER_SETTINGS: '- User Settings -',
        HELP_CENTER: '- Help Center -',
        AUTH_PAGE: '- Authentication -',
        MISSING_PARTS: '- Missing Products -',
        NOT_FOUND: '- Not Found -',
        NOT_ALLOWED: '- Not Allowed -',
        ERROR_FALLBACK: '- Error -'
    }
};

export const getPageTitle = (page?: string) => {
    return page && page !== 'Home'
        ? `Compass ${page} Choice Logistics`
        : defaultPageTitle;
};
