import { RefObject, useEffect, useState } from 'react';

import { BaseOptionType } from 'antd/lib/select';

import { Account } from '@apis/users/UsersApi.types';
import { areUniqueArraysEqual } from '@helpers/array';
import { FormattedTreeData, getNestedData } from '@helpers/treeHelper';
import { useTreeData } from '@hooks/useTreeData';
import { useUser } from '@hooks/useUser';
import {
    ComparedFilterValues,
    SelectedFilters
} from '@organisms/allFiltersWrapper/AllFiltersWrapper';

interface TreeFilterReturnType {
    accounts: Account[] | undefined;
    accountsLoading: boolean;
    onTreeInit: VoidFunction;
    selectedKeys: string[];
    onClearClick?: (newSelectedKeys: string[]) => void;
    areSelectedKeysChanged: boolean;
    handleChange: (newValue: string[]) => void;
    allTreeFilterHandleChange: (newValue: string[]) => void;
    onRemoveSearchBadge: (value: string) => void;
    onRemoveFilterSearchBadge: (value: string) => void;
    treeData?: BaseOptionType[];
    popupRef: RefObject<HTMLDivElement>;
    getCustomPopupContainer: (triggerNode: HTMLElement) => HTMLElement;
    hasKeys?: boolean;
}

export const useTreeFilter = ({
    selectedFilterItems,
    isDefaultKeysDependency,
    setComparedResults,
    setNewFilters,
    selectInitialValue,
    data,
    filterType,
    compareType,
    isAccountAndSubsSetting,
    withCode = true,
    filterDataSetting
}: {
    selectedFilterItems: string[];
    isDefaultKeysDependency?: boolean;
    setComparedResults?: (newComparedValue: ComparedFilterValues) => void;
    setNewFilters?: (newFilter: SelectedFilters) => void;
    selectInitialValue?: string[];
    data?: FormattedTreeData[];
    filterType?: string;
    compareType?: string;
    isAccountAndSubsSetting: boolean;
    withCode?: boolean;
    filterDataSetting?: string;
}): TreeFilterReturnType => {
    const { accounts, accountsLoading = false, fetchAccounts } = useUser();

    const [treeData, setTreeData] = useState<
        {
            title: string;
            value: string;
            children: { title: string; value: string }[];
        }[]
    >([]);

    const getTreeData = async () => {
        if (isAccountAndSubsSetting) {
            const accounts = await fetchAccounts?.(true);

            const accountsData = accounts ? accounts.data : null;

            const dataByAccounts = accountsData
                ? accountsData.map(
                      ({ accountNumber, accountId, subAccounts }) =>
                          ({
                              title: accountNumber,
                              value: accountId.toString(),
                              children: subAccounts.map((el) => {
                                  const value = `${accountId}-${el.subAccountId}`;

                                  return {
                                      title: el.description,
                                      value
                                  };
                              })
                          } || [])
                  )
                : [];

            setTreeData(dataByAccounts);
        } else {
            const newTreeData = data?.map(
                ({
                    name: parentName,
                    code: parentCode,
                    children: parentChildren
                }) => {
                    return {
                        title: parentName,
                        value: parentCode,
                        children: getNestedData(
                            withCode,
                            parentCode,
                            parentChildren
                        )
                    };
                }
            );

            if (newTreeData) {
                setTreeData(newTreeData);
            }
        }
    };

    useEffect(() => {
        void getTreeData();
    }, [filterDataSetting]);

    const {
        popupRef,
        getCustomPopupContainer,
        onTreeInit,
        handleChange,
        onRemoveSearchBadge,
        areSelectedKeysChanged,
        selectedKeys,
        onClearClick,
        hasKeys
    } = useTreeData({
        defaultSelectedKeys: selectedFilterItems,
        isDataLoading: accountsLoading,
        isDefaultKeysDependency
    });

    const onRemoveFilterSearchBadge = (value: string) => {
        const withoutRemoved = selectedKeys.filter((item) => item !== value);
        const isArraysEqual = areUniqueArraysEqual(
            selectInitialValue || [],
            withoutRemoved
        );
        setNewFilters?.({ [`${filterType}`]: withoutRemoved });
        setComparedResults?.({ [`${compareType}`]: isArraysEqual });
    };

    const allTreeFilterHandleChange = (newValue: string[]) => {
        handleChange(newValue);

        setNewFilters?.({ [`${filterType}`]: newValue });
        const isArraysEqual = areUniqueArraysEqual(
            selectInitialValue ?? [],
            newValue
        );
        setComparedResults?.({ [`${compareType}`]: isArraysEqual });
    };

    return {
        accounts,
        accountsLoading,
        areSelectedKeysChanged,
        onTreeInit,
        selectedKeys,
        onClearClick,
        handleChange,
        allTreeFilterHandleChange,
        onRemoveSearchBadge,
        onRemoveFilterSearchBadge,
        treeData,
        popupRef,
        hasKeys,
        getCustomPopupContainer
    };
};
