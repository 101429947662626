import { DATE_FORMAT, TIME_FORMAT } from '@apis/users/UsersApi.types';
import { AccountSubAccount, PreCheckDTO } from '@customTypes/api/api.types';
import {
    Service,
    SERVICE_TYPES,
    ServicesKeys
} from '@customTypes/deliveryServices/deliveryServices.types';
import { BaseStepData, NullableDate } from '@customTypes/general/general.types';
import { PartFormValue } from '@customTypes/general/part.types';
import {
    CountryFormFields,
    DeliveryStepFormValues,
    PartQuantity,
    SelectPartsStepOrderFormValues,
    SerialsStepFormValues,
    ShipmentDetailsFormValues,
    StepperMainInfo
} from '@customTypes/general/stepper.types';
import {
    AccountWarehouse,
    NullableAccountWarehouse
} from '@customTypes/general/warehouse.type';
import { UseModalReturnType } from '@hooks/useModal';
import { UseInsufficientQuantity } from '@organisms/orders/shared/insufficientQuantityModal/useInsufficientQuantity';

export enum ACTIVE_ORDER_TYPE {
    INBOUND_REPLENISHMENT_BY_PART = 'inboundReplenishmentByPart',
    INBOUND_REPLENISHMENT_BY_PART_DEFECTIVE = 'defectiveInboundReplenishmentByPart',
    INBOUND_REPLENISHMENT_BY_UPLOAD = 'inboundReplenishmentByUpload',
    INBOUND_REPLENISHMENT_BY_UPLOAD_DEFECTIVE = 'defectiveInboundReplenishmentByUpload',
    INBOUND_RETURN_BY_PART = 'inboundReturnByPart',
    GOOD_BY_WAREHOUSE = 'goodBySslOrder',
    DEFECTIVE_BY_WAREHOUSE = 'defectiveBySslOrder',
    GOOD_BY_PART = 'goodByPartOrder',
    TRANSFER_BY_WAREHOUSE = 'bySslTransfer',
    TRANSFER_BY_WAREHOUSE_DEFECTIVE = 'defectiveBySslTransfer'
}

export type ActiveOrderFormValues = SelectPartsStepOrderFormValues &
    DeliveryStepFormValues &
    SerialsStepFormValues &
    ShipmentDetailsFormValues;

export interface ActiveOrderStepData extends BaseStepData {
    initialValues?: Partial<ActiveOrderFormValues>;
    onFinish?: (values: ActiveOrderFormValues) => void;
}

export interface DeliveryTypeStepFormValues {
    warehouse: string;
    deliveryType: string;
    includeLabels: boolean;
    date: NullableDate;
    time?: number;
    earliestReady?: boolean;
}

export enum ORDER_BY_PART_STEPS {
    SELECT_PARTS = 'Select Products',
    DELIVERY = 'Delivery',
    REFERENCE_NUMBERS = 'Reference Numbers',
    SHIPMENTS_DETAILS = 'Shipment Details',
    ORDER_REVIEW = 'Order Review'
}

export type OutboundOrderFormValues = SelectPartsStepOrderFormValues &
    DeliveryTypeStepFormValues &
    ShipmentDetailsFormValues &
    SerialsStepFormValues & { remember: boolean };

export interface GetModalDataProps {
    description: string;
    submitText: string;
    onOk: VoidFunction;
    onCancelButtonClick: VoidFunction;
}

export interface SelectPartByWarehouseStepFormValues extends PartQuantity {
    warehouse: string;
}

export type DeliveryByWarehouseStepFormValues = Omit<
    DeliveryTypeStepFormValues,
    'warehouse'
> &
    CountryFormFields &
    PostalCityState;

export interface OutboundOrderStepData extends BaseStepData {
    initialValues?: Partial<OutboundOrderFormValues>;
    onFinish?: (values: OutboundOrderFormValues) => void;
}

export type ChosenWarehouseForSubmit = Partial<
    Pick<AccountWarehouse, 'countryCode' | 'code'>
>;

export interface ConfirmModalData {
    reduceFunction: VoidFunction;
    showReduceOption?: boolean;
}

export enum ORDER_TICKET_STATUS {
    CANCELLED = 'Cancelled',
    UNDELIVERABLE = 'Undeliverable',
    COMPLETED = 'Completed',
    PENDING = 'Pending'
}

export enum ORDER_TYPES {
    INBOUND_REPLENISHMENT = 'Replenishment',
    INBOUND_RETURN = 'Return',
    TRANSFER = 'Warehouse Transfer',
    CUSTOMER_ORDER = 'Customer Order',
    CUSTOMER_DELIVERY = 'Customer Delivery'
}

export enum TICKET_TABS {
    MILESTONES = 'Milestones',
    DOCUMENTS = 'Documents',
    COMMENTS = 'Comments',
    REVERSE_LOGISTICS = 'Reverse Logistics'
}

export interface HazardClass {
    hazardClassId: number;
    name: string;
    description: string;
}

export interface MainDelivery
    extends Pick<
        Service,
        | 'carrierCode'
        | 'carrierName'
        | 'eta'
        | 'prepAndTransitMinutes'
        | 'cutoffTime'
        | 'earliestReady'
        | 'id'
    > {
    localDestinationTimeZone?: string;
    localPickUpTimeZone?: string;
    serviceName?: string;
    service?: SERVICE_TYPES;
    serviceType?: ServicesKeys;
    serviceOption?: SERVICE_TYPES;
    returnPackAvailability?: boolean;
    isEarliest?: boolean;
    serviceLevel?: string;
    type?: string;
    selectedDate?: string;
    selectedTime?: number;
    offset?: string;
    trackingNumber?: string;
    displayName?: string;
}

export enum COUNTRY_VARIANT {
    ORIGIN = 'Origin Country',
    DESTINATION = 'Destination Country'
}

export type MainInfoPart = Pick<
    PartFormValue,
    'partNumber' | 'quantity' | 'hazard' | 'isDualUse' | 'subs' | 'weight'
>[];

export interface CompletedInboundSummaryItems {
    mainData: StepperMainInfo;
    convertedWeight: string;
    dateFormatKey?: DATE_FORMAT;
    timeFormatKey?: TIME_FORMAT;
}

export interface ChosenDelivery {
    warehouse: NullableAccountWarehouse;
    service: MainDelivery | null;
}

export interface OrderReferencesFormValues {
    orderReferenceNumber?: string;
    orderReferenceNumber2?: string;
    orderReferenceNumber3?: string;
    orderReferenceNumber4?: string;
}

export interface PostalCityState extends Record<`postalCode` | 'city', string> {
    state?: string;
}

export interface BaseShipmentDetails extends Pick<PostalCityState, 'state'> {
    companyName?: string;
    addressLine1?: string;
    addressLine2?: string;
    deliveryCity?: string;
    contactPerson?: string;
    email?: string;
    phone?: string;
    phoneCode?: string;
    extension?: string;
}

export interface ShipmentDetails extends BaseShipmentDetails {
    comment?: string[];
    provinceCode?: string;
    postalCode?: string;
}

export interface OrderReferenceLabels {
    orderReferenceNumber1Label: string | null;
    orderReferenceNumber2Label: string | null;
    orderReferenceNumber3Label: string | null;
    orderReferenceNumber4Label: string | null;
}

export type OrderReferenceLabelsDTO = AccountSubAccount;

export interface DefectiveStatus {
    isDefective?: boolean;
}

export interface PreCheckAndSubmit
    extends Omit<UseInsufficientQuantity, 'isWarehouseLoading'> {
    isPreCheckOpen: boolean;
    onOrderCancelOk?: VoidFunction;
    isOtherWarehouseLoading: boolean;
    togglePreCheck: VoidFunction;
    handlePreCheck: VoidFunction;
    handleReduceOrder: VoidFunction;
    isAllPartsGone: boolean;
    isSubmitOrderLoading: boolean;
    isReduceLoading: boolean;
    resetAllSteps?: VoidFunction;
    getPreCheckData: (data: PreCheckDTO) => void;
    isPreCheckLoading: boolean;
    onStartNewOrder: VoidFunction;
    closePreCheck: VoidFunction;
    closeAllModals: VoidFunction;
    confirmModalInfo: UseModalReturnType<ConfirmModalData>;
}
