import React from 'react';

import {
    GetOrdersDTO,
    GetOrdersTransformedResponse,
    TransformedTrackOrder
} from '@apis/trackOrders/TrackOrdersApi.types';
import { EmptyState } from '@atoms/emptyState/EmptyState';
import { isMobileAndTablet } from '@constants/breakpoints';
import { UseMutationResult } from '@customTypes/api/api.types';
import { useUserSettings } from '@hooks/useUserSettings';
import { PaginationTable } from '@molecules/paginationTable/PaginationTable';
import { TableHeaderMobile } from '@molecules/tableHeaderMobile/TableHeaderMobile';
import { getOrdersListColumns } from '@organisms/trackOrders/orderList/columns';
import { OrderCards } from '@organisms/trackOrders/orderList/shared/orderCards/OrderCards';
import { OrderTicketRow } from '@organisms/trackOrders/orderList/shared/orderTicketRow/OrderTicketRow';
import { useOrdersList } from '@organisms/trackOrders/orderList/useOrdersList';

import styles from './OrdersList.module.less';

export interface OrdersListProps {
    getOrdersList: (config: GetOrdersDTO) => void;
    isLoading: boolean;
    options?: UseMutationResult<GetOrdersTransformedResponse>;
    width: number;
}

const NoData = () => (
    <EmptyState
        className={styles.NoDataText}
        title="No matching items found"
        subtitle="Try changing the search or filter criteria"
    />
);

export const OrdersList = ({
    getOrdersList,
    options,
    width,
    isLoading
}: OrdersListProps) => {
    const {
        items,
        totalItemsCount,
        pagesCount,
        page,
        onPageChange,
        onTableChange,
        sorting,
        search,
        perPageOptions,
        onExpandOrder,
        orderTicketsById,
        isNoResults,
        goToDetailsPage,
        expandedOrderRows,
        statusParam
    } = useOrdersList({ getOrdersList, options, isLoading });

    const { timeFormatKey, dateFormatKey } = useUserSettings();

    return (
        <>
            {isMobileAndTablet(width) ? (
                <>
                    {isNoResults ? (
                        <>
                            <TableHeaderMobile title="Orders" />
                            <NoData />
                        </>
                    ) : (
                        <OrderCards
                            dataSource={items}
                            isMobile={isMobileAndTablet(width)}
                            onDetailsClick={goToDetailsPage}
                            isLoading={isLoading}
                            total={totalItemsCount}
                            currentPage={page}
                            pageCount={pagesCount}
                            perPage={perPageOptions}
                            onPageChange={onPageChange}
                            onExpand={onExpandOrder}
                            orderTicketsById={orderTicketsById}
                            search={search}
                        />
                    )}
                </>
            ) : (
                <PaginationTable
                    isLoading={isLoading}
                    columns={getOrdersListColumns({
                        sorting,
                        search,
                        timeFormatKey,
                        dateFormatKey,
                        statusParam
                    })}
                    customContainerClassName={styles.Container__ListTable}
                    dataSource={items}
                    total={totalItemsCount}
                    pageCount={pagesCount}
                    currentPage={page}
                    perPage={perPageOptions}
                    onChange={onTableChange}
                    onPageChange={onPageChange}
                    showTotal={true}
                    locale={{ emptyText: !isLoading ? <NoData /> : '' }}
                    onRow={(record: TransformedTrackOrder) => ({
                        onClick: (e) => goToDetailsPage(e, record)
                    })}
                    expandable={{
                        onExpand: (expanded, { orderId }) =>
                            onExpandOrder(orderId, expanded),
                        expandedRowKeys: expandedOrderRows,
                        expandedRowRender: (record) => (
                            <OrderTicketRow
                                tickets={
                                    orderTicketsById?.[record?.orderId]?.data
                                }
                                isLoading={
                                    orderTicketsById?.[record?.orderId]
                                        ?.isLoading
                                }
                                searchResult={search}
                                onClick={(e, proNum?: string) =>
                                    goToDetailsPage(e, record, proNum)
                                }
                            />
                        )
                    }}
                    scroll={{ x: 1080 }}
                />
            )}
        </>
    );
};
