import React from 'react';

import { Form } from 'antd';

import { useGetClosestWarehousesMutation } from '@apis/manageInventory/GetClosestWarehouses.api';
import { GetTransformedWarehousesResponse } from '@apis/manageInventory/GetClosestWarehouses.type';
import { Button } from '@atoms/button/Button';
import { Checkbox } from '@atoms/checkbox/Checkbox';
import { EmptyState } from '@atoms/emptyState/EmptyState';
import { TitleField } from '@atoms/titleField/TitleField';
import { getRequiredRule } from '@helpers/validationHelpers';
import { useWeightOrDistanceConverter } from '@hooks/useWeightOrDistanceConverter';
import { CountrySelect } from '@molecules/countrySelect/CountrySelect';
import { PaginationTable } from '@molecules/paginationTable/PaginationTable';
import { getColumns } from '@organisms/manageInventory/locateClosestWarehouse/columns';
import styles from '@organisms/manageInventory/locateClosestWarehouse/LocateClosestWarehouse.module.less';
import { useLocateClosestWarehouse } from '@organisms/manageInventory/locateClosestWarehouse/useLocateClosestWarehouse';
import { PostalCodeCityStates } from '@organisms/selectProductsAndDestination/PostalCodeCityStates';

export interface LocateClosestWarehouseProps {
    getData: ReturnType<typeof useGetClosestWarehousesMutation>[0];
    isLoading: boolean;
    closestWarehouseData?: GetTransformedWarehousesResponse;
    reset: VoidFunction;
}

export const LocateClosestWarehouse = ({
    getData,
    isLoading,
    closestWarehouseData,
    reset
}: LocateClosestWarehouseProps) => {
    const [form] = Form.useForm();

    const {
        countries,
        country,
        states,
        hasStates,
        isPostalCodeCityStatesVisible,
        isSubmitButtonDisabled,
        isTableVisible,
        sorting,
        includeInactiveWarehouse,
        onChangeCountry,
        onInputChange,
        onGetWarehouseList,
        onTableChange
    } = useLocateClosestWarehouse({
        form,
        getData,
        isLoading,
        reset,
        cacheId: closestWarehouseData?.cacheId
    });

    const isNoResults =
        !closestWarehouseData?.data?.items?.length && !isLoading;
    const { convertDistanceValues, measureDistanceUnits } =
        useWeightOrDistanceConverter();

    const convertedResultsTitle = `Results are limited to ${convertDistanceValues(
        300
    )} ${measureDistanceUnits}`;

    return (
        <>
            <Form
                name="locateClosestWarehouseForm"
                className={styles.Form}
                form={form}
            >
                <div className={styles.Inputs}>
                    <div className={styles.CountrySelect}>
                        <CountrySelect
                            allCountries={countries}
                            onSelect={(_, data) => onChangeCountry(data)}
                            onClear={onChangeCountry}
                            onCountryChange={onInputChange}
                            rules={[getRequiredRule('country', 'Select')]}
                        />
                    </div>
                    {isPostalCodeCityStatesVisible && (
                        <>
                            <div className={styles.PostalCodeCityStates}>
                                <PostalCodeCityStates
                                    form={form}
                                    selectedCountry={country}
                                    country={country}
                                    onChangeDestination={onInputChange}
                                    hasStates={hasStates}
                                    states={states}
                                    onChangeSelectState={onInputChange}
                                />
                            </div>
                            <div className={styles.SubmitButton__Wrapper}>
                                <Button
                                    text="Locate Warehouse"
                                    theme="primary"
                                    disabled={isSubmitButtonDisabled}
                                    className={styles.SubmitButton}
                                    onClick={onGetWarehouseList}
                                    isLoading={isLoading}
                                />
                            </div>
                        </>
                    )}
                    <Form.Item
                        name="inactiveWarehouse"
                        id="inactiveWarehouse"
                        valuePropName="checked"
                        initialValue={true}
                        className={styles.Checkbox}
                    >
                        <Checkbox
                            label="Include Inactive Warehouse"
                            onChange={onInputChange}
                        />
                    </Form.Item>
                </div>
            </Form>
            {isTableVisible && (
                <div>
                    <TitleField
                        blockTitle="Warehouse List"
                        blockText={convertedResultsTitle}
                        theme="large"
                        className={styles.Table__Title}
                    />

                    <PaginationTable
                        currentPage={1}
                        dataSource={closestWarehouseData?.data?.items}
                        rowKey={(record) => record.sslId}
                        columns={getColumns({
                            sorting,
                            hasActiveForSubaccount: includeInactiveWarehouse,
                            measureDistanceUnits,
                            convertDistanceValues
                        })}
                        locale={
                            isNoResults
                                ? {
                                      emptyText: (
                                          <EmptyState
                                              title="No Warehouse found"
                                              className={styles.Table__Empty}
                                          />
                                      )
                                  }
                                : undefined
                        }
                        isLoading={isLoading}
                        onChange={onTableChange}
                        onPageChange={() => null}
                        scroll={{ x: 'max-content' }}
                    />
                </div>
            )}
        </>
    );
};
