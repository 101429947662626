import { fetchBaseQuery } from '@reduxjs/toolkit/query';

import {
    queryWithIntercept,
    QueryWithIntercept
} from '@apis/queryWithIntercept';
import { AppController } from '@configs/AppController';
import { RootState } from '@store/index';

export const baseQuery = (token: string | null) => {
    const appConfig = AppController.getInstance().config;

    return fetchBaseQuery({
        baseUrl: `${appConfig?.api_host}/api`,
        prepareHeaders: (headers, { getState }) => {
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            const state = getState() as RootState;
            const flowId = state.user.currentFlowId;

            if (flowId) {
                headers.set('x-correlation-id-flow', flowId);
            }

            headers.set('content-type', 'application/json');

            return headers;
        }
    });
};

export const baseQueryWithIntercept: QueryWithIntercept =
    queryWithIntercept(baseQuery);
