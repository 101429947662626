import {
    useUploadInboundFileMutation,
    useUploadDefectiveInboundFileMutation,
    useUploadOutboundFileMutation,
    useUploadDefectiveOutboundFileMutation,
    useUploadTransfersFileMutation,
    useUploadDefectiveTransfersFileMutation
} from 'APIServices/files/Files.api';
import {
    useGetDownloadInboundTemplateMutation,
    useGetInboundColumnsMappingMutation,
    useResetInboundFileMutation,
    useValidateInboundFileMutation,
    useSubmitInboundOrdersMutation,
    useGetInboundGroupingOrdersMutation
} from 'APIServices/inboundByBulk/InboundByBulk.api';
import {
    useGetDownloadOutboundTemplateMutation,
    useGetOutboundColumnsMappingMutation,
    useResetOutboundFileMutation,
    useValidateOutboundFileMutation,
    useSubmitOutboundOrdersMutation,
    useGetOutboundGroupingOrdersMutation,
    useGetOutboundAvailableServicesMutation
} from 'APIServices/outboundByBulk/OutboundByBulk.api';
import {
    useGetAvailableCarriersMutation,
    useGetDownloadTransfersTemplateMutation,
    useGetTransfersColumnsMappingMutation,
    useResetTransfersFileMutation,
    useValidateTransfersFileMutation,
    useGetTransfersGroupingOrdersMutation,
    useSubmitTransfersOrdersMutation
} from 'APIServices/transfersByBulk/TransfersByBulk.api';
import {
    AccountSubAccount,
    RequestStatusWithData
} from 'utils/types/api/api.types';
import {
    DELIVERY_DATA,
    DELIVERY_TRANSPORT_TYPES,
    Service,
    Services
} from 'utils/types/deliveryServices/deliveryServices.types';
import {
    BaseStepData,
    FILE_EXTENSIONS,
    ListItem
} from 'utils/types/general/general.types';

export type UploadFileFormValues = Record<string, string> & {
    includeLabels: boolean;
};

export type BulkFormValues = UploadFileFormValues;

export type BulkStepColumn = {
    id: string;
    [key: string]: string;
};

export interface InboundBulkStepData extends BaseStepData {
    initialValues?: Record<string, never>;
    onFinish?: (values: BulkFormValues) => void;
}

export enum BULK_INBOUND_STEPS {
    UPLOAD_FILE = 'Upload File',
    VALIDATE_FILE = 'Validate File',
    REVIEW = 'Order Review'
}

export enum BULK_OUTBOUND_STEPS {
    UPLOAD_FILE = 'Upload File',
    VALIDATE_FILE = 'Validate File',
    DELIVERY = 'Delivery',
    REVIEW = 'Order Review'
}

export enum BULK_TRANSFERS_STEPS {
    UPLOAD_FILE = 'Upload File',
    VALIDATE_FILE = 'Validate File',
    DELIVERY = 'Delivery',
    REVIEW = 'Order Review'
}

export type BulkSteps =
    | BULK_INBOUND_STEPS[]
    | BULK_OUTBOUND_STEPS[]
    | BULK_TRANSFERS_STEPS[];

export interface BulkStepData extends BaseStepData {
    initialValues?: Record<string, never>;
    onFinish?: (values: BulkFormValues) => void;
}

export interface DownloadTemplateDTO extends AccountSubAccount {
    fileExtension: FILE_EXTENSIONS;
    deliveryMethod?: DELIVERY_TYPES_VALUES;
}

export enum DELIVERY_TYPES_TITLES {
    DELIVERY = 'Delivery',
    CUSTOMER_PICK_UP = 'Customer/Technician Pick Up'
}

export enum DELIVERY_TYPES_TEXT {
    DELIVERY = 'Performed by Choice Logistics or a third-party carrier',
    DELIVERY_DEFECTIVE = 'Performed by Choice Logistics or a third-party carrier',
    CUSTOMER_PICK_UP = 'A customer/technician will pick up the order from the warehouse'
}

export enum DELIVERY_TYPES_VALUES {
    DELIVERY = 'Delivery',
    CUSTOMER_PICK_UP = 'CustomerPickUp'
}

export enum BULK_TYPES {
    CUSTOMER_BULK = 'BulkOutbound',
    REPLENISHMENT_BULK = 'BulkInbound',
    TRANSFERS = 'TransfUpload'
}

export enum BULK_TEMPLATES_NAMES {
    CUSTOMER_GOOD = 'Customer_Delivery_Upload_Template',
    RETURNS_DEFECTIVE_FROM_CHOICE = 'Return_Defective_From_Choice_Upload_Template',
    REPLENISHMENT_GOOD = 'Replenishment_Upload_Template',
    RETURNS_DEFECTIVE_TO_CHOICE = 'Return_Defective_To_Choice_Upload_Template',
    TRANSFERS_GOOD = 'Transfer_Good_Upload_Template',
    TRANSFERS_DEFECTIVE = 'Transfer_Defective_Upload_Template'
}

export interface ColumnMappingDTO extends AccountSubAccount {
    fileId: string;
    deliveryMethod?: DELIVERY_TYPES_VALUES;
}

export type ColumnMappingResponse = RequestStatusWithData<{
    mappingResults: MappingResultsItem[];
    unmappedHeaders: string[];
}>;

export interface MappingResultsItem {
    header: string;
    isMapped: boolean;
    isRequired: boolean;
    orderIndex?: number;
}

export type ManuallyMappedItem = MappingResultsItem & {
    manuallyMappedValue?: string;
};

export interface BulkApi {
    useTemplateBulkFile:
        | typeof useGetDownloadOutboundTemplateMutation
        | typeof useGetDownloadInboundTemplateMutation
        | typeof useGetDownloadTransfersTemplateMutation;
    useResetBulkFile:
        | typeof useResetOutboundFileMutation
        | typeof useResetInboundFileMutation
        | typeof useResetTransfersFileMutation;
    useUploadBulkFile:
        | typeof useUploadInboundFileMutation
        | typeof useUploadDefectiveInboundFileMutation
        | typeof useUploadOutboundFileMutation
        | typeof useUploadDefectiveOutboundFileMutation
        | typeof useUploadTransfersFileMutation
        | typeof useUploadDefectiveTransfersFileMutation;
    useColumnMapping:
        | typeof useGetOutboundColumnsMappingMutation
        | typeof useGetInboundColumnsMappingMutation
        | typeof useGetTransfersColumnsMappingMutation;
    useValidateBulkFile:
        | typeof useValidateInboundFileMutation
        | typeof useValidateTransfersFileMutation
        | typeof useValidateOutboundFileMutation;
    useSubmitOrders:
        | typeof useSubmitInboundOrdersMutation
        | typeof useSubmitOutboundOrdersMutation
        | typeof useSubmitTransfersOrdersMutation;
    useGetGroupingOrders:
        | typeof useGetTransfersGroupingOrdersMutation
        | typeof useGetInboundGroupingOrdersMutation
        | typeof useGetOutboundGroupingOrdersMutation;
    useGetCarriers?: typeof useGetAvailableCarriersMutation;
    useGetServices?:
        | typeof useGetOutboundAvailableServicesMutation
        | typeof useGetAvailableCarriersMutation;
}

export interface ValidateFileError {
    header: string;
    rowNumber: number;
    statusCode: number;
    message: string;
}

interface ValidateFileDataRow {
    key: string;
    originalKey: string | null;
    value: string;
}

export type ValidateFileData = ValidateFileDataRow[];

export type ValidateFileResponse = RequestStatusWithData<{
    fileValidationId: string;
    errors: ValidateFileError[];
    fileData: ValidateFileData[];
}>;

interface ColumnMappingType {
    mapColumnFrom: string;
    mapColumnTo: string;
}

export interface ValidateFileDTO extends AccountSubAccount {
    fileId: string;
    columnMappings: ColumnMappingType[];
    deliveryMethod?: DELIVERY_TYPES_VALUES;
}
export interface GroupedPartItem {
    quantity: number;
    serialNumbers: string[];
    itemReferenceNumber: string | null;
}

export interface GroupedOrderItem {
    partNumber: string | null;
    partItems: GroupedPartItem[];
    alternativePartNumbers?: string[];
    expandedPartNumber?: string;
}

export interface GroupedOrder {
    serviceDisplayName: string;
    serviceLevel: string;
    partQuantity: number;
    destinationSSL: string;
    destinationSSLAlias?: string;
    waybill: string;
    carrierCode: string;
    shipFromCountry: string;
    originCountryName: string;
    originCountryCode: string;
    orderReferenceNumberLabel: ListItem | null;
    orderReferenceNumberLabel2: ListItem | null;
    orderReferenceNumberLabel3: ListItem | null;
    orderReferenceNumberLabel4: ListItem | null;
    partList: GroupedOrderItem[];
    originSSL?: string;
    etaTimeZone?: string;
    originSSLAlias?: string;
    carrierName?: string;
    service?: string;
    eta?: string;
    prepAndTransitMinutes?: number;
    timeZone?: string;
    contactName?: string;
    contactPhone?: string;
    contactPhoneExtension?: string;
    email?: string;
    destinationCompanyName?: string;
    destinationCountry?: string;
    destinationPostalCode?: string;
    destinationCity?: string;
    destinationState?: string;
    destinationAddressLine1?: string;
    destinationAddressLine2?: string;
}

export type GroupingOrdersResponse = RequestStatusWithData<{
    groupedOrders: GroupedOrder[];
    orderListId: string;
}>;

export interface GroupingOrdersDTO extends AccountSubAccount {
    validationId?: string;
    selectedServiceLevel?: string;
    orderListId?: string;
}

export interface SubmitOrdersDTO extends AccountSubAccount {
    orderListId: string;
    serviceLevel?: string;
    returnPackAvailability?: boolean;
}

export type GetAvailableCarriersResponse =
    RequestStatusWithData<AvailableServicesDataType>;

export interface GetAvailableCarriersDTO extends AccountSubAccount {
    validationId: string;
}

export interface GetAvailableServicesDTO extends GetAvailableCarriersDTO {
    transportationType: DELIVERY_TRANSPORT_TYPES;
}

export interface DeliveryType {
    key: DELIVERY_TRANSPORT_TYPES;
    data: AvailableServicesDataType;
}

export interface SelectedServiceType {
    type: DELIVERY_TRANSPORT_TYPES;
    serviceLevel: string;
    deliveryService?: keyof typeof DELIVERY_DATA;
}

export interface AvailableServicesDataType {
    orderListId: string;
    availableServicesCount: number;
    availableServices: Service[];
    returnPackAvailability: boolean;
    id: string;

    willCall: Services;
    scheduled: Services;
    immediate: Services;
}

export type GetAvailableServicesResponse =
    RequestStatusWithData<AvailableServicesDataType>;

export type ValidationSocketResponse = {
    progress: string;
};
